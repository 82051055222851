


































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class App extends Vue {
  private drawer: boolean = true;
  private items: Array<object> = [
    //TODO Fetch this list from Server
    { icon: "mdi-home", text: "Dashboard", route: "/", show: 200 },
    {
      icon: "mdi-file-document",
      text: "Resources",
      route: "/componentdoc",
      show: 200,
    },
    {
      icon: "mdi-chart-pie",
      "icon-alt": "mdi-chevron-down",
      text: "Reports",
      model: false,
      show: 10,
      children: [
        {
          icon: "mdi-database",
          text: "Personalized Secondary Order Dump",
          route:
            "/reports/Download?subscriptionKey=E9124E2F-2C30-4AD7-A2DD-81813D6162DC",
          show: 10,
        },
      ],
    },
    {
      icon: "mdi-account-circle",
      "icon-alt": "mdi-chevron-down",
      text: "Position Management",
      model: false,
      show: 10,
      children: [
        {
          icon: "mdi-plus",
          text: "Create Position",
          route: "/positioncode/create",
          show: 10,
        },
      ],
    },
    {
      icon: "mdi-file-document",
      text: "Communication",
      route: "/communication",
      show: 200,
    },
    {
      icon: "mdi-file-document",
      text: "Create Campaign",
      route: "/campaign/create",
      show: 200,
    },
    {
      icon: "mdi-file-document",
      text: "Battleground List",
      route: "/battleground/list",
      show: 200,
    },
    {
      icon: "mdi-file-document",
      text: "VanSales List",
      route: "/vansales/list",
      show: 200,
    },
    {
      icon: "mdi-file-document",
      text: "Derived KPIs",
      route: "/derivedkpis/list",
      show: 200,
    },
    {
      icon: "mdi-file-document",
      text: "International Discount",
      route: "/internationalDiscount/list",
      show: 200,
    },
    {
      icon: "mdi-file-document",
      text: "Requests",
      route: "/deadOutletApproval/list",
      show: 200,
    },
    {
      icon: "mdi-file-document",
      text: "Review Outlet Requests",
      route: "/reviewOutletRequests/view",
      show: 200,
    },
    {
      icon: "mdi-file-document",
      text: "Region wise ODS",
      route: "/regionwiseODS/create",
      show: 200,
    },
  ];
  private framesource: boolean = false;

  mounted() {
    const url = new URL(document.location.href);
    if (url.searchParams.get("source") == "iframe") {
      this.framesource = true;
    }
    const hash = url.searchParams.get("hash");
    if (hash != null) {
      localStorage.setItem("apiToken", hash);
    }
  }
}
