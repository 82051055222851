import { ReportRequestStatus } from "@/interfaces/Enums";
import { dashboardAPIUrl } from '@/envConfig'
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import _Vue from "vue";

const baseUrl = dashboardAPIUrl;

export class ReportsHub
{
    private static vueToken : string = `VueDashboardToken ${localStorage.getItem('apiToken')}`;
    private static manuallyClosed : boolean = false;
    private static connected : Promise<void> | null = null;
    private static connection : HubConnection = new HubConnectionBuilder()
        .withUrl(`${baseUrl}ReportStatus`, {
            headers: {
                'Authorization': ReportsHub.vueToken
            }
        })
        .configureLogging(LogLevel.Information)
        .build();

    private static reportsHub : _Vue;
    
    public static install(Vue: typeof _Vue){
        ReportsHub.reportsHub = new Vue();
        Vue.prototype.$reportsHub = ReportsHub.reportsHub;
        ReportsHub.connection.serverTimeoutInMilliseconds = 1200 * 1000;
        ReportsHub.connection.on('UpdateReportStatus', (requestId: number, status : ReportRequestStatus, downloadLink : string) => {
            ReportsHub.reportsHub.$emit(`${requestId}-status-update`, {requestId, status, downloadLink});
        })
        Vue.prototype.$reportsHub.startReportsHub = (requestId : number) => {
            ReportsHub.start().then(() =>
                ReportsHub.joinGroup(requestId))
            ReportsHub.connection.onclose(() => {
                if(!ReportsHub.manuallyClosed) 
                    ReportsHub.start().then(() =>
                        ReportsHub.joinGroup(requestId));
            })
        }
        Vue.prototype.$reportsHub.stopReportsHub = () => {
            ReportsHub.manuallyClosed = true;
            if(!ReportsHub.connected) return;
            return ReportsHub.connected
                .then(() => ReportsHub.connection.stop())
                .then(() => ReportsHub.connected = null)
        }
    }

    private static start() : Promise<void> {
        ReportsHub.connected = ReportsHub.connection.start()
            .catch(err => {
                ReportsHub.reportsHub.$emit('signalR-failure');
                console.error('Failed to connect with hub', err);
                return new Promise((res,rej) => setTimeout(() => ReportsHub.start().then(res).catch(rej)))
            })
        return ReportsHub.connected;
    }

    private static joinGroup(requestId : number){
        return ReportsHub.connection.invoke('JoinGroup', requestId);
    }
}