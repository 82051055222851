import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light:{
                backgroundLight: '#ffffff',
                backgroundDark: '#f5f5f5',
                primary: '#0097cd',
                primaryFABlue: '#0097cd',
                primaryDark: '#005486',
                primaryLight: '#e5f8ff',
                whiteHigh: '#ffffff',
                darkGreen: '#307146',
                lightGreen: '#4ba428',
                success: '#4ba428',
                magenta: '#ec4497',
                purple: '#a139a7',
                cyan: '#49acaa',
                yellow: '#f9c446',
                orange: '#da5330',
                red: '#ed3833',
                highlightGray: '#d8d8d8',
                activeTextBoxBlue: '#e1f9ff'
            }
        }
    }
});
