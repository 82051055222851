import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import {ReportsHub} from './plugins/signalR';
import router from './router/routes';

Vue.config.productionTip = false
Vue.use(ReportsHub);

new Vue({
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app')
