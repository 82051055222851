import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: () => import("./../components/Home.vue"),
    name: "home",
  },
  {
    path: "/positioncode/create",
    component: () => import("./../components/positioncode/Create.vue"),
    name: "positioncodecreate",
  },
  {
    path: "/CompetitorSurveyManagement/create",
    component: () =>
      import("./../components/CompetitorSurveyManagement/Create.vue"),
    name: "CompetitorSurveyManagementcreate",
  },
  {
    path: "/CompetitorSurveyManagement/add",
    component: () =>
      import("./../components/CompetitorSurveyManagement/AddCompetitor.vue"),
    name: "competitorsurveymanagementadd",
  },
  {
    path: "/battleground/list",
    component: () => import("./../views/Gamification/List.vue"),
    name: "battlegroundlist",
  },
  {
    path: "/vansales/list",
    component: () => import("./../views/VanSales/List.vue"),
    name: "VanSalesList",
  },
  {
    path: "/componentdoc",
    component: () => import("./../components/ComponentsDoc.vue"),
    name: "componentdoc",
  },
  {
    path: "/communication",
    component: () => import("@/components/common/Communication.vue"),
    name: "communication",
  },
  {
    path: "/campaign/create",
    component: () => import("@/components/Campaign/New/Create.vue"),
    name: "campaignCreate",
    props: true
  },
  {
    path: "/campaign/manage",
    component: () => import("@/components/Campaign/ManageCampaign/Header.vue"),
    name: "campaignManage",
  },
  {
    path: "/MTConfiguration",
    component: () =>
      import("@/components/MTConfiguration/ConfigurationSetting.vue"),
    name: "MTConfiguration",
  },
  {
    path: "/RequestManager",
    component: () =>
      import(
        "@/components/ModernTrade/RequestHandler/OpenStockRequestHandler/Requestlist.vue"
      ),
    name: "MTRequestManager",
  },
  {
    path: "/chain/manage",
    component: () => import("@/components/ModernTrade/ChainModule/Manage.vue"),
    name: "MTChainManage",
  },
  {
    path: "/chain/create",
    component: () => import("@/components/ModernTrade/ChainModule/Create.vue"),
    name: "MTChainCreate",
  },

  {
    path: "/reports/download",
    component: () => import("@/components/reports/Download.vue"),
    name: "downloadReport",
  },
  {
    path: "/dmsnotifications/create",
    component: () => import("@/views/DMSNotifications/Create.vue"),
    name: "createDMSNotifications",
  },
  {
    path: "/dmsnotifications/list",
    component: () => import("@/views/DMSNotifications/List.vue"),
    name: "View DMS Notifications",
  },
  {
    path: "/derivedkpis/list",
    component: () => import("./../views/DerivedKPIs/List.vue"),
    name: "DerivedKPIsList",
  },
  {
    path: "/internationalDiscount/list",
    component: () => import("./../views/InternationalDiscount/List.vue"),
    name: "InternationalDiscountList",
  },
  {
    path: "/deadOutletApproval/list",
    component: () => import("./../views/DeadOutletApproval/List.vue"),
    name: "DeadOutletApprovalList",
  },
  {
    path: "/reviewOutletRequests/view",
    component: () => import("./../views/ReviewRequestDetails/View.vue"),
    name: "ReviewOutletRequests",
    props: { id: 0 },
  },
  {
    path: "/attendance-norms/list",
    component: () => import("./../views/AttendanceNorms/List.vue"),
    name: "AttendanceNorms",
  },
  {
    path: "/attendance-norms/listpage",
    component: () => import("../views/AttendanceNorms/NormsView.vue"),
    name: "AttendanceNorms",
  },
  {
    path: "/journeyPlanType/list",
    component: () => import("./../views/HierarchyMapping/Hirarchymapping.vue"),
    name: "HierarchyMapping",
  },
  {
    path: "/assetmanagement/list",
    component: () => import("./../views/AssetManagement/List.vue"),
    name: "AssetManagement",
  },
  {
    path: "/regionwiseODS/create",
    component: () => import("./../views/ODSRegionWise/View.vue"),
    name: "regionwiseODS",
    },
  {
        path: '/CouponManagement/CouponCreate',
        component: () => import('./../views/CouponManagement/CouponCreate.vue'),
        name: 'CouponManagement'
    },
  {
        path: '/CouponManagement/SchemeCreation',
        component: () => import('./../views/CouponManagement/SchemeCreation.vue'),
        name: 'SchemeCreate'
    },
    {
        path: '/CouponManagement/SchemeList',
        component: () => import('./../views/CouponManagement/SchemeList.vue'),
        name: 'SchemeList'
    },
    {
      path: '/DistributorManegement/Segmentation',
      component: () => import('./../views/Segmentation/Create.vue'),
      name: 'DistributorSegmentation'
  },
  {
    path: '/DistributorManegement/Channel',
    component: () => import('./../views/DistributorChannel/List.vue'),
    name: 'DistributorChannel'
  },
    {
      path: "/CouponManagement/SchemeView",
      component: () => import("./../views/CouponManagement/SchemeView.vue"),
      name: "SchemeView",
      props: { id: 0 },
    },
   
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
